// Действия
function dataAct(obj, data) {
	const act = obj.action ?? null;
	switch (act) {
		case 'setInput':
			data.setValue(obj.name, obj.value);
			break;
		case 'setImg':
			data.setImg(obj.name, obj.value);
			break;
		case 'setFile':
			data.setFile(obj.name, obj.value);
			break;
		default:
			alert('[Мастер] [Данные] Действие не определено');
	}
}

export default dataAct;
