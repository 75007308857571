import { makeAutoObservable, toJS } from "mobx";

const def = '<no>'
const atp =['number', 'date']

class Data {
	s = {
		current: def,
		[def]: {
			page: 0,
			view: 0,
			key: '',
			filter: []
		}
	}
	constructor(props) {
		makeAutoObservable(this);
	}
	// Установить текущую строку
	setKey(k) {
		const c = this.s.current ?? def
		this.s[c].key = k
	}
	// Вернуть текущую страницу
	get key() {
		const c = this.s.current ?? def
		return this.s[c].key ?? ''
	}
	// Установить текущую страницу
	setPage(p) {
		const c = this.s.current ?? def
		this.s[c].page = p
		document.querySelector('.tbl-main-body').scrollTop = 0
	}
	// Вернуть текущую страницу
	get page() {
		const c = this.s.current ?? def
		return this.s[c].page ?? 0
	}
	// Установить текущуее представление
	setView(v, table, action) {
		const c = this.s.current
		this.s[c].view = v
		this.setPage(0)
		this.initFilter(table, action, true)
	}
	// Вернуть текущуее представление
	get view() {
		const c = this.s.current ?? def
		return this.s[c].view ?? 0
	}
	// Записать текущий код таблицы
	setCurrent(c) {
		this.s.current = c
		if (this.s[c]) return
		const o = toJS(this.s[def])
		this.s[c] = o
	}
	// Удалить данные по текущему коду таблицы
	kill() {
		const c = this.s.current
		this.s.current = def
		delete this.s[c]
	}
	// Вернуть текущиее фильтры
	get filter() {
		const c = this.s.current ?? def
		return this.s[c].filter ?? []
	}
	// Изменить данные фильтра
	setFilter(i, obj, j) {
		const cd = this.s.current
		const a = [...this.s[cd].filter]
		i = a.findIndex(el => el._id === i)
		if (j === undefined || j === null) {
			a[i].data = obj;
			this.s[cd].filter = a
			return;
		}
		const b = [...a[i].data];
		b[j].value = obj.value;
		b[j].type = obj.type;
		a[i].data = b
		this.s[cd].filter = a
	}
	// Сброс значений фильтра
	initFilter(table, action, act) {
		const cd = this.s.current
		const e = {
			type: 'table',
			action: 'initFilter'
		}
		if (this.s[cd].filter.length > 0 && !act) {
			action(e)
			return
		}
		let v = this.s[cd].view
		v = table.s.view.list[v]
		let a = table.s.col ?? []
		const obj = {
			value: "",
			type: 0,
		};
		a = a.filter(el => el.viewId === v._id && el.filter)
		a = a.map(el => {
			const t = el.type ?? ''
			return {
				_id: el._id,
				name: el.name,
				type: el.type,
				data: atp.indexOf(t) < 0 ? obj: [obj, obj]
			}
		})
		this.s[cd].filter = a
		action(e)
	}
}

export default new Data();
