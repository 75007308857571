import Win from './win';
import './style.css';

const Login = (props) => {
	let cls = ['skin-login', 'login-box'];
	cls = cls.join(' ');
	const stl = {
		backgroundImage: 'url(/task/img/login_bg.jpg)',
	};
	return (
		<div className={cls} style={stl}>
			<div className="shadow"></div>
			<div className="container">
				<Win />
			</div>
		</div>
	);
};

export default Login;
