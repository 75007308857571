import { useEffect, useState } from 'react';
import Error from '../error';
import Label from './lable';
import './style.css';

const InputArea = ({ s, v, e, setValue }) => {
	const {
		_id,
		name,
		title = '',
		style = {},
		disabled = false,
		type = 'type',
	} = s;

	// Значение
	const [val, setVal] = useState(v);
	// Массив ошибок - получаем пропсами из mobx
	const [err, setErr] = useState(e);
	// Индикатор Показывали ошибки или нет
	const [status, setStatus] = useState(false);

	useEffect(() => {
		// Защита от сброса курсора в конец текста
		if (val !== v) {
			setVal(v);
		}
	}, [v, val]);

	useEffect(() => {
		setErr(e);
	}, [e]);

	let cls = ['skin-input-base', 'input-base', 'input-area'];

	if (err) cls.push('error');
	cls = cls.join(' ');

	return (
		<div className={cls} style={style}>
			<Label label={s?.label} />
			<div className="area" id={_id} onClick={onFocus}>
				<textarea
					rows="10"
					name={name}
					onChange={onChange}
					value={val}
					disabled={disabled}
					title={title}
				></textarea>
			</div>

			{/* Ошибки */}
			{status ? (
				<Error err={err} onClick={() => setStatus(false)} />
			) : null}
		</div>
	);

	function onChange(e) {
		setStatus(false);
		setErr(null);
		setVal(e.target.value);

		setValue(name, e.target.value, type);
	}

	// Показываем список ошибок
	function onFocus(e) {
		if (err && !status) {
			setStatus(true);
		}
	}
};

export default InputArea;
