// import Filter from "./filter";
import "./style.css";
import { stlCol } from "skin/table/content/main/fn";

const Item = ({ data, item }) => {
	let cls = ["skin-cmptbl-main-head-item", "tbl-main-head-item"];
	cls = cls.join(" ");
	const st = stlCol(data);
	return (
		// <th className={cls}>
		<th className={cls} style={st}>
			<p>{data.title}</p>
		</th>
	);
};

export default Item;
