import { makeAutoObservable } from 'mobx';
import { space } from 'skin/event';

class Data {
	s = {
		// current: 'code',
		// code: {
		// 	table: {
		// 		code: {
		// 			page: 0,
		// 			row: 0,
		// 		},
		// 	},
		// 	page: 0,
		// },
	};
	constructor(props) {
		makeAutoObservable(this);
	}

	// получаем данные по текущему мастеру (Таблицы, тек.страница)
	get current() {
		const c = this.s.current;
		if (!c) return {};

		const r = this.s[c] ?? {};
		return r;
	}

	get curPage() {
		return this.current?.page ?? 0;
	}

	// Номер следующей страницы начиная с 0
	nextPage(l) {
		const c = this.current?.page ?? 0;
		if (c + 1 < l) return c + 1;
		if (c < 0) return 0;
		return c;
	}
	goNext(l, action, finish) {
		let c = this.current?.page ?? 0;
		let n = this.nextPage(l);
		if (c !== n) this.setPage(n);
		if (c === n) {
			if (!finish) return this.exit(action);
			return action({
				type: 'master',
				action: 'finish',
				finish,
			});
		}
		const o = {
			type: 'master',
			action: 'reload',
		};
		action(o);
	}
	exit(action) {
		console.log('Последняя страница');
		const o = {
			type: 'master',
			action: 'exit',
		};
		return action(o);
	}
	// Переходим на следующую страницу
	next(action, ms) {
		const l = ms.pageLen;
		let c = this.current.page;
		let n = this.nextPage(l);
		if (c + 1 > l) return;

		const nextPage = ms.getPage(n);
		if (nextPage.off) {
			const o = {
				type: 'master',
				action: 'check',
			};
			action(o);
			return;
		}

		this.setPage(n);
	}

	// // Вернуться на шаг
	back() {
		let c = this.current.page;
		--c;
		if (c >= 0) this.setPage(c);
	}

	// Запоминаем текущий мастер, по нему будут выводится все данные
	setDef(code, table) {
		if (!code) return;
		this.s.current = code;
		// Инициализируем базовую структу
		this.init(code, table);
	}

	// Установить текущую страницу
	setPage(p) {
		p = p < 0 ? 0 : p;
		this.s[this.s.current].page = p;
	}

	// Установить текущуее представление таблицы
	setTblPg(tbl, p, l, action) {
		if (p < 1 || !p) p = 1;
		if (p > l) p = l;
		const s = this.s;
		s[s.current].table[tbl].page = p;

		const o = {
			type: 'master',
			action: 'reload',
			table: tbl,
		};

		action(o);
	}

	// Установить текущую строку таблицы
	setTblRow(data) {
		const { key, table, form, info } = data;
		const s = this.s;

		const row = s[s.current].table[table].row ?? '';
		if (row !== key) {
			s[s.current].table[table].row = key;
			return;
		}

		const o = {
			type: 'form',
			title: form.title,
			data: {
				code: form.code,
				info,
			},
		};
		space(o, true);
	}

	// Инициализируем пустую структуру
	init(code, table) {
		if (this.s[code]) return;
		this.s[code] = {
			table: {},
			page: 0,
		};

		table.forEach((el) => {
			this.s[code].table[el.code] = {
				page: 1,
				row: 0,
			};
		});
	}

	// Удалить данные по текущему коду
	kill() {
		const s = this.s;

		delete s[s.current];
		s.current = '';
	}
}

export default new Data();
