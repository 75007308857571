import { useEffect, useState } from 'react';
import './style.css';

const Text = ({ s, v }) => {
	const { _id, name, title = '', label, width, align, style = {}, off } = s;
	let cls = ['skin-text', 'fld-text'];
	cls = cls.join(' ');
	const [val, setVal] = useState();
	useEffect(() => {
		if (!label) return setVal(v ?? '');

		const i = label.indexOf(`%i`);
		const r = label.indexOf(`%r`);

		if (i >= 0) {
			if (!v) return setVal(label.slice(0, i) + label.slice(i + 2));
			insert(i);
		} else if (r >= 0) {
			if (!v) return setVal(label.slice(0, r) + label.slice(r + 2));
			replace(r);
		} else return setVal(label);

		// вставка - находим %i и вставляем вмето него значения из БД
		function insert(i) {
			const res = label.slice(0, i) + v + label.slice(i + 2);
			setVal(res);
		}

		// замена  находим %r и замещаем все на длинну значения из БД
		function replace(r) {
			let tail = label.slice(r + 2);
			tail = tail.length > v.length ? tail.slice(v.length) : '';
			const res = label.slice(0, r) + v + tail;
			setVal(res);
		}
	}, [v, label]);

	if (width) {
		style.width = width;
	}
	if (align) {
		style.textAlign = align;
	}
	if(off) style.display = 'none'
	return (
		<div
			className={cls}
			style={style}
			id={_id}
			title={title}
			name={name ?? 'text' + _id}
		>
			{val}
		</div>
	);
};

export default Text;
