import space from './space';

const data = {
	/* wait, login, ok */
	type: 'wait',
	shadow: false,
	wait: false,
	dictionary: false,
	message: false,
	space,
	stack: [],
};

export default data;
