import './style.css';

const Item = (props) => {
	let cls = ['skin-cmptbl-com-cnt-item', 'cmptbl-com-cnt-item'];
	cls = cls.join(' ');
	const id = props.data.id;
	const title = props.data.title;
	const st = { backgroundImage: `url(skin/img/com_${id}.svg)` };
	return (
		<div className={cls} onClick={() => props.click(id)}>
			<span className="ico" style={st}></span>
			<span className="cmptbl-head-com-cnt-item-tit">{title}</span>
		</div>
	);
};

export default Item;
