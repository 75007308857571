import { useEffect, useState } from 'react';
import Error from '../error';
import Label from './lable';
import { dictionary } from 'skin/event';
import './style.css';

const InputNumber = ({ s, v, e, setValue }) => {
	const {
		_id,
		name,
		keys,
		title = '',
		disabled = false,
		width = '90px',
		style = {},
		type = 'number',
		autoComplete = 'off',
	} = s;

	const min = 0
	const iStl = {
		width: width,
	};
	const dtype = s.dictionary?.type;
	const dcode = s.dictionary?.code;
	// Значение
	const [val, setVal] = useState(v);
	// Массив ошибок - получаем пропсами из mobx
	const [err, setErr] = useState(e);
	// Индикатор Показывали ошибки или нет
	const [status, setStatus] = useState(false);

	useEffect(() => {
		// Защита от сброса курсора в конец текста
		if (val !== v) {
			setVal(v);
		}
	}, [v, val]);

	useEffect(() => {
		setErr(e);
	}, [e]);

	// Иконка
	let img;
	if (dtype) {
		img =
			dtype === 'code'
				? { backgroundImage: 'url("skin/img/dct_code.svg")' }
				: { backgroundImage: 'url("skin/img/dct_str.svg")' };
	}

	img = img ? (
		<div className="input-ico" style={img} onClick={icoClick}></div>
	) : null;

	let cls = ['skin-input-base', 'input-number', 'input-base'];
	if (err) cls.push('error');
	cls = cls.join(' ');

	return (
		<div className={cls} style={style}>
			<Label label={s?.label} />
			<div className="input">
				<input
					id={_id}
					type={type}
					name={name}
					value={val}
					min={min}
					disabled={disabled}
					autoComplete={autoComplete}
					onChange={onChange}
					onFocus={onFocus}
					style={iStl}
					title={title}
				/>
				{/* Иконка */}
				{img}
			</div>

			{/* Ошибки */}
			{status ? (
				<Error err={err} onClick={() => setStatus(false)} />
			) : null}
		</div>
	);

	function onChange(e) {
		setStatus(false);
		setErr(null);
		setVal(e.target.value);

		setValue(name, e.target.value, type);
	}

	// Показываем список ошибок
	function onFocus(e) {
		if (err && !status) {
			setStatus(true);
		}
	}

	function icoClick() {
		if (!dtype) return;
		// Отображаем дешифратор
		setStatus(false);
		setErr(null);

		function setV(v) {
			Array.isArray(v) ? keysVal(v) : setValue(name, v, type);
		}
		const o = { code: dcode, func: setV };
		dictionary(o);

		function keysVal(v) {
			setValue(name, v[0], type);
			if(!keys) return
			const key = keys.split(',')
			if(key.length!==v.length-1) return
			for(let i = 1; i < v.length; i++) {
				const name = key[i-1].split(':')[0]
				const type = key[i-1].split(':')[1]
				setValue(name, v[i], type)

			}
		}
	}
};

export default InputNumber;
