import { makeAutoObservable } from 'mobx';
import def from './def';

class Data {
	s = {
		def,
	};
	constructor(props) {
		makeAutoObservable(this);
	}
}

export default new Data();
