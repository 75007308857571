import React from 'react';
import './style.css';

const LogoL = ({ task }) => {
	let cls = ['cmp-logo-l', 'logo-l'];
	cls = cls.join(' ');

	let img = task ? 'task' : 'skin';
	img = 'url(' + img + '/img/logo-l.png)';

	const stl = { backgroundImage: img };

	return <div className={cls} style={stl} onClick={lnk} />;

	function lnk(e) {}
};

export default LogoL;
