import './style.css';

const Fld = (props) => {
	let cls = ['skin-flds-fld', 'flds-fld'];
	cls = cls.join(' ');
	const st = { backgroundImage: `url(${props.pict})` };
	return (
		<div className={cls}>
			<p>{props.label}</p>
			<div style={st}>
				<label>
					<input
						id={props.id}
						name={props.name}
						type={props.type}
						placeholder={props.holder}
						required
					/>
				</label>
			</div>
		</div>
	);
};

export default Fld;
