import oprt from 'skin/data/table/operator'

// Данные для фильтрации
function query(a = []) {
	const q = {id: {}, date: {}, all: {}}
	for (let i=0; i<a.length; ++i) {
		const {name, type, data} = a[i]
		if (Array.isArray(data)) {
			arrData()
		} else {
			valData()
		}
		// Простое значение фильтра
		function valData() {
			let value = data.value
			if (value === '') return
			if (value === '""') value = ''
			const key = oprt.str[data.type].query
			if (type === 'id') {
				q.id[name] = {}
				q.id[name][key] = value
				return
			}
			if (type === 'bool') {
				q.all[name] = {}
				q.all[name][key] = value === 'Да'
				return
			}
			q.all[name] = {}
			q.all[name][key] = value
			if(key === '$regex') q.all[name] = {...q.all[name], '$options': 'i'}
		}
		// Диапазонные значения фильтра
		function arrData() {
			for (let i = 0; i < 2; ++i) {
				if (data[i].value === '') return
				const key = oprt.all[data[i].type].query
				switch (type) {
					case 'date':
						if (!q.date[name]) q.date[name] = {}
						q.date[name][key] = data[i].value
						break;
					case 'number':
						if (!q.all[name]) q.all[name] = {}
						q.all[name][key] = +data[i].value
						break;
					default:
						if (!q.all[name]) q.all[name] = {}
						q.all[name][key] = data[i].value
				}
			}
		}
	}
	return q
}

export { query }
