import axios from 'axios';
import cnfTask from 'config_task';
import { type, wait } from 'skin/event';

const api = axios.create({
	// Axios Interceptors
	// авто добавление кук
	withCredentials: true,
	// Базавый url
	baseURL: cnfTask.url,
});

// Добавляем хедеры в запросы от клиента к серверу
api.interceptors.request.use((config) => {
	// Do something before request is sent
	wait(true);
	// Акецесс токен из localStorage
	const token = localStorage.getItem('token') ?? null;
	config.headers.Authorization = `Bearer ${token}`;
	config.headers.Task = cnfTask.code;
	return config;
});

// Перехват 401 ошибки Не авторизованный пользователь
api.interceptors.response.use(
	(response) => {
		wait();
		return response;
	},
	async (error) => {
		// Обрабатываем ошибку
		const original = error.config;
		if (!error.response) {
			wait();
			return error;
		}

		let val = error.response.status === 401;
		val = val && error.config;
		val = val && !error.config._isRetry;
		if (val) {
			// Ставим метку чтобы не зациклиться
			original._isRetry = true;
			try {
				// Выполняем рефреш
				const response = await axios.get(
					`${cnfTask.url}api/auth/refresh`,
					{
						withCredentials: true,
					}
				);
				if (!response) {
					// Ничего не получилось Очищаем токены и переходим на авторизацию
					console.log('API interceptors 401 error: ', error);
					localStorage.clear('token');
					type('login');
					return Promise.reject(error);
				}
				// Сохраняем новый токен и Повторно выполняем запрос
				localStorage.setItem('token', response.data.accessToken);
				return api.request(original);
			} catch (e) {
				console.log('API interceptors error: ', e);
				localStorage.clear('token');
				type('login');
			} finally {
				wait();
			}
		}
		wait();
		throw error;
	}
);

export default api;
