import { makeAutoObservable, runInAction, toJS } from 'mobx';
import api from 'skin/http';

const def = {
	values: {
		fld: {},
		table: {},
		img: {},
		file: {},
	},
	errors: {},
	changed: [],
};

class Data {
	s = {
		// current: 'code',
		// code: {
		// 	values: {
		// 		fld: {},
		// 		table: {},
		// 		img: {},
		// 		file: {},
		// 	},
		// 	errors: {},
		// 	changed: [],
		// }
	};
	constructor(props) {
		makeAutoObservable(this);
	}

	// Вернет текущий код мастера
	get current() {
		return this.s.current ?? '';
	}
	// Вернет данные по текущему мастеру
	get curData() {
		return this.s[this.current] ?? def;
	}

	findRow(id, tbl) {
		if (!id && !tbl) return null;
		const t = this.curData.values.table[tbl];
		if (!t) return null;
		const o = t.list.find((e) => e.key === id);

		return o;
	}

	// Записываем значение для опрределенного id
	setValue(key, value) {
		const d = this.curData;
		if (!d.changed.includes(key)) d.changed.push(key);

		// Добавляем новый объект в values
		d.values.fld[key] = value;

		// Удаляем ошибки
		d.errors[key] = null;
	}

	// Записываем картинку
	setImg(key, fd) {
		const s = this.curData;
		if (!s.changed.includes(key)) s.changed.push(key);

		const v = s.values;
		// Удаление Изображения
		if (!fd) {
			v.fld[key] = '';
			delete v.img[key];
			return;
		}
		// Выбор нового Изображения
		v.fld[key] = 'new';
		const f = fd.get(key);
		v.img[key] = {
			name: f.name,
			size: f.size,
			mimeType: f.type,
			type: f.name.split('.').pop(),
			file: fd,
		};
	}

	// Записываем файл
	setFile(key, fd) {
		const s = this.curData;
		if (!s.changed.includes(key)) s.changed.push(key);

		const v = s.values;
		// Удаление Файл
		if (!fd) {
			v.fld[key] = '';
			delete v.file[key];
			return;
		}
		// Выбор нового файла
		v.fld[key] = 'new';
		const f = fd.get(key);
		v.file[key] = {
			name: f.name,
			size: f.size,
			mimeType: f.type,
			type: f.name.split('.').pop(),
			file: fd,
		};
	}

	// Меняем значение в ячейке таблицы
	setCell(tbl, key, idx, value, func) {
		const s = this.curData;
		const t = s.values?.table[tbl];
		const i = t.list.findIndex((e) => e.key === key);
		if (i === -1) return;

		const a = [...t.list];
		const v = a[i].list[idx];
		if (func) a[i].list[idx] = func();
		else a[i].list[idx] = v !== value ? value : '';
		// перезаписываем новый лист
		t.list = a;
	}

	// Заменяем строку таблицы
	setRow(tbl, key, row) {
		const s = this.curData;
		const t = s.values?.table[tbl];
		const i = t.list.findIndex((e) => e.key === key);
		if (i === -1) return;

		const a = [...t.list];
		a[i].list = row;

		// перезаписываем новый лист
		t.list = a;
	}

	// Есть ли не сохраненные изменения в форме
	isChanged() {
		const s = this.curData;
		const changed = s.changed;
		return changed.length ? true : false;
	}

	// Записываем ошибки
	setError(err) {
		const s = this.curData;
		s.errors = err;
	}

	// Запуск функции на исполнение
	action(n) {
		const s = this.curData;
		const obj = s.func ?? {};
		let o = obj.list[n];
		if (obj.current !== o.id) {
			obj.current = o.id;
			return;
		}
		if (!o.action) {
			alert('Действие не определено');
			return;
		}
		o.action.title = o.action.title ?? o.title;
		o = toJS(o.action);
	}

	getSwitch(key, type, image, file, d, o) {
		switch (type) {
			case 'id':
				o.id[key] = d[key];
				break;
			case 'number':
				o.all[key] = +d[key];
				break;
			case 'bool':
				o.all[key] = d[key];
				break;
			case 'date':
				o.date[key] = d[key];
				break;
			case 'time':
				o.time[key] = d[key];
				break;
			case 'img':
				o.all[key] = d[key];
				if (image[key]) {
					o.img[key] = {
						mimeType: image[key].mimeType,
						name: image[key].name,
						size: image[key].size,
						type: image[key].type,
					};
				}
				break;
			case 'file':
				o.all[key] = d[key];
				if (file[key]) {
					o.file[key] = {
						mimeType: file[key].mimeType,
						name: file[key].name,
						size: file[key].size,
						type: file[key].type,
					};
				}
				break;
			default:
				o.all[key] = d[key];
				break;
		}
		return o;
	}
	// Получение всех данных по структуре для валидации
	getAll(ms, check = false) {
		const fld = ms.s.fld;
		const s = this.curData;
		const d = s.values.fld;
		const image = s.values.img;
		const file = s.values.file;
		const table = s.values.table;

		let o = {
			id: {},
			all: {},
			date: {},
			time: {},
			img: {},
			file: {},
			table: table,
		};

		fld.forEach((el) => {
			const key = el.name;
			const type = el.type;
			o = this.getSwitch(key, type, image, file, d, o);
		});

		// Возвращям Объекты без файлов
		if (!check) return o;

		let formData = new FormData();

		for (let key in image) {
			const img = image[key];
			if (img?.file && img.file instanceof FormData) {
				formData.append(key, img.file.get(key));
			}
		}
		for (let key in file) {
			const f = file[key];
			if (f?.file && f.file instanceof FormData) {
				formData.append(key, f.file.get(key));
			}
		}

		for (let key in o) {
			formData.append(key, JSON.stringify(o[key]));
		}
		return formData;
	}

	// Получение измененых данных по структуре
	getChanged(ms) {
		const fld = ms.s.fld;
		const s = this.curData;
		const d = s.values.fld;
		const image = s.values.img;
		const file = s.values.file;
		const table = s.values.table;

		let formData = new FormData();

		for (let key in image) {
			const img = image[key];
			if (img?.file && img.file instanceof FormData) {
				formData.append(key, img.file.get(key));
			}
		}

		for (let key in file) {
			const f = file[key];
			if (f?.file && f.file instanceof FormData) {
				formData.append(key, f.file.get(key));
			}
		}

		let o = {
			id: {},
			all: {},
			date: {},
			time: {},
			dt: {},
			img: {},
			file: {},
			table: table,
			update: d.update,
		};

		s.changed.forEach((el) => {
			const f = fld.find((e) => e.name === el);

			const key = el;
			const type = f.type;
			o = this.getSwitch(key, type, image, file, d, o);
		});

		for (let key in o) {
			formData.append(key, JSON.stringify(o[key]));
		}
		return formData;
	}

	// Получение данных с сервера
	getData(d, action, ms, story, tbl) {
		const code = story.s.current;
		if (typeof d === 'string') d = JSON.parse(d);
		const value = {
			info: d.info,
			type: d.type ?? '',
			// Получение сортировки по данным из структуры
			table: getSort(ms.s.table, story.current.table),
		};
		const data = this.getAll(ms);
		data.value = value;

		const page = story.curPage;

		const self = this;
		const config = {
			method: 'post',
			url: `api/master/data/${code}/${page}`,
			data,
		};

		api(config)
			.then(function (response) {
				const r = response.data.result;
				self.setData(r, tbl, action);
			})
			.catch(console.log);
	}

	setData(r, tbl, action) {
		runInAction(() => {
			// очищаем список измененых полей и ошибок
			const s = this.curData;
			s.changed = [];
			s.errors = {};

			if (tbl) {
				s.values.table[tbl] = r?.table[tbl] ?? [];
			} else {
				// получаем поля
				if (!r?.fld) console.log('getData - Нет данных по полям');
				else {
					for (let key in r?.fld) {
						s.values.fld[key] = r.fld[key];
					}
				}

				// получаем таблицы
				if (!r?.table) console.log('getData - Нет данных по таблицам');
				else {
					for (let key in r.table) {
						s.values.table[key] = r.table[key];
					}
				}
			}

			const o = {
				type: 'master',
				action: 'loadData',
			};

			action(o);
		});
	}

	// формируем пустые данные
	defData(action, ms) {
		const { values, errors } = this.curData;

		if (ms.s.fld) {
			ms.s.fld.forEach((el) => {
				// Добавляем новый пустой объект в values
				if (!values.fld[el.name]) {
					values.fld[el.name] = '';
					errors[el.name] = null;
				}
			});
		}

		const o = {
			type: 'master',
			action: 'defData',
		};

		action(o);
	}

	// Запоминаем текущий мастер, по нему будут выводится все данные
	setDef(code) {
		if (!code) return;
		this.s.current = code;
		// Инициализируем базовую структу
		this.init(code);
	}

	// Инициализируем пустую структуру
	init(code) {
		if (this.s[code]) return;
		this.s[code] = def;
	}

	// Удалить данные по текущему коду
	kill() {
		const s = this.s;

		delete s[s.current];
		s.current = '';
	}
}

function getSort(tbl, pg) {
	const o = {};

	tbl.forEach((e) => {
		// Выбираем колонки с сортировкой
		let s = e.col.filter((el) => el.sort);
		// Сортируем поля по модулю
		s = s.sort((a, b) => Math.abs(a.sort) - Math.abs(b.sort));

		const sort = {};
		s.forEach((el) => {
			if (el.sort > 0) sort[el.name] = 1;
			else sort[el.name] = -1;
		});

		let p = 1;
		if (pg) {
			p = pg[e.code]?.page;
		}

		o[e.code] = { sort, page: p };
	});
	return o;
}

export default new Data();
