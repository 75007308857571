import api from 'skin/http';
import { type } from 'skin/event';
import menu from 'skin/store/menu';

function Login(login, password, setMess) {
	api.post('api/auth/login', { login, password })
		.then(function (response) {
			localStorage.setItem('token', response.data.accessToken);
			localStorage.setItem('user', response.data.user);
			type('ok');
			menu.setLoad()
		})
		.catch((e) => {
			console.log('Login error', e.response?.data);
			localStorage.clear('token');
			localStorage.clear('user');
			let msg = e.response?.data?.message;
			if (!msg) return;
			if (!Array.isArray(msg)) msg = [msg];
			setTimeout(() => setMess(msg), 1000);
		});
}

export default Login;
