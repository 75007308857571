import './style.css';
import { stlCol } from 'skin/table/content/main/fn';

const Col = (props) => {
	let cls = ['skin-tbl-main-body-row-col', 'tbl-main-body-row-col'];
	// Стили для ячейки или колонки
	const o = props.def;
	if (o?.type === 'id') cls.push('id');
	cls = cls.join(' ');

	// Значение ячейки
	const t = props.data ? props.data : 'Не определено';
	const d = value(props.data, o);

	const st = stlCol(o, true);
	return (
		<td className={cls} style={st}>
			<div title={t.toString()}>{d}</div>
		</td>
	);
};
// Обработка присланного значения
function value(val, def) {
	val = val ?? '';
	if (val === '') return <br />;
	if (!def) return val;
	switch (def.type) {
		case 'date':
			return date(val);
		case 'dt':
			return dt(val);
		case 'bool':
			return bool(val);
		default:
			return val;
	}
}
// Преобразование даты
function date(val) {
	val = typeof val === 'string' ? new Date(val) : val;
	val = val.toLocaleString().split(',')[0];
	return val;
}
// Преобразование даты и времени
function dt(val) {
	val = typeof val === 'string' ? new Date(val) : val;
	val = val.toLocaleString()
	return val;
}
// Декодирование булевского типа
function bool(val) {
	return val === true ? 'Да' : 'Нет';
}
export default Col;
