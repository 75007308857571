const Wait = (props) => {
	if (!props.data) return null;
	return (
		<div className="skin-wait">
			<img src="task/img/wait.gif" alt="Wait" />
		</div>
	);
};

export default Wait;
