import './style.css';

const Btn = ({ title, click }) => {
	let cls = ['skin-btn'];
	cls = cls.join(' ');
	return (
		<div className={cls}>
			<button className="clr-t2 btn" onClick={click}>
				{title}
			</button>
		</div>
	);
};

export default Btn;
