import Dict from 'skin/dict';
import Message from 'skin/message';
import Page from 'skin/page';
import Info from 'skin/info';
import Case from './case';
import Shadow from './shadow';
import Wait from './wait';
import './style.css';

const Space = (props) => {
	let cls = ['skin-space', 'space'];
	cls = cls.join(' ');

	return (
		<div className={cls}>
			<Page data={props.data}>
				<Case space={props.data.space} />
			</Page>
			<Dict data={props.data.dictionary} />
			<Message data={props.data.message} />
			<Info data={props.data.info} />
			<Shadow data={props.data.shadow} />
			<Wait data={props.data.wait} />
		</div>
	);
};

export default Space;
