import Empty from 'skin/cmp/empty';
import InputBool from './bool';
import InputDT from './dt';
import InputBase from './input-base';
import InputNumber from './number';
import InputArea from './area';
import InputImg from './input-img';
import InputFile from './input-file';
import Text from './text';
import Btn from './button'
import Editor from './editor';
import './style.css';


const Fields = ({ data, v, e, action }) => {
	let cls = ['skin-cmp-field', 'cmp-field'];
	cls = cls.join(' ');
	if (!data) return <Empty type={'field'} />;
	const s = data ?? {}; //Структура и стиль
	switch (data?.type) {
		case 'date':
			v = v ? new Date(v).toLocaleDateString('en-CA') : '';
			return (
				<div className={cls}>
					<InputBase s={s} v={v} e={e} setValue={setFld}></InputBase>
				</div>
			);
		case 'time':
			return (
				<div className={cls}>
					<InputBase s={s} v={v} e={e} setValue={setFld}></InputBase>
				</div>
			);
		case 'number':
			return (
				<div className={cls}>
					<InputNumber
						s={s}
						v={v}
						e={e}
						setValue={setFld}
					></InputNumber>
				</div>
			);
		case 'dt':
			return (
				<div className={cls}>
					<InputDT s={s} v={v}></InputDT>
				</div>
			);
		case 'update':
			return (
				<div className={cls}>
					<InputDT s={s} v={v}></InputDT>
				</div>
			);
		case 'bool':
			return (
				<div className={cls}>
					<InputBool s={s} v={v} e={e} setValue={setFld}></InputBool>
				</div>
			);
		case 'area':
			return (
				<div className={cls} style={{ width: '100%' }}>
					<InputArea s={s} v={v} e={e} setValue={setFld}></InputArea>
				</div>
			);
		case 'img':
			return (
				<div className={cls}>
					<InputImg s={s} v={v} e={e} setValue={setImg}></InputImg>
				</div>
			);
		case 'file':
			return (
				<div className={cls}>
					<InputFile s={s} v={v} e={e} setValue={setFile}></InputFile>
				</div>
			);
		case 'txt':
			const w = s.width ?? 'auto';
			const align = s.align ?? 'left';
			return (
				<div className={cls} style={{ width: w, textAlign: align }}>
					<Text s={s} v={v}></Text>
				</div>
			);
		case 'btn':
			return (
				<div className={cls}>
					<Btn s={s} action={action}></Btn>
				</div>
			)
		case 'editor':
			return(
				<div className={cls}>
					<Editor s={s} v={v} e={e} setValue={setFld}/>
				</div>
			)
		default:
			return (
				<div className={cls}>
					<InputBase s={s} v={v} e={e} setValue={setFld}></InputBase>
				</div>
			);
	}

	function setFld(n, v) {
		const o = {
			type: 'data',
			action: 'setInput',
			name: n,
			value: v,
		};
		action(o);
	}

	function setImg(n, v, del) {
		const o = {
			type: 'data',
			action: 'setImg',
			name: n,
			value: v,
		};
		action(o);
	}

	function setFile(n, v, del) {
		const o = {
			type: 'data',
			action: 'setFile',
			name: n,
			value: v,
		};
		action(o);
	}
};

export default Fields;
