import { makeAutoObservable, runInAction } from 'mobx';
import api from 'skin/http';
import { body } from './dict_test.js';

class Data {
	s = {
		title: '',
		head: 'code',
		width: {
			0: '50px',
		},
		body,
	};
	constructor(props) {
		makeAutoObservable(this);
	}
	//
	getData(code) {
		const self = this;
		if (!code) return;
		const config = {
			method: 'get',
			url: `api/data/dict/${code}`,
			params: {},
		};
		api(config)
			.then(function (response) {
				const r = response.data.result;
				runInAction(() => {
					for (let key in r) self.s[key] = r[key];
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	// поднять уровень просмотра
	up() {
		const cur = this.s.body.current;
		const o = this.s.body.list.find((el) => el._id === cur);
		this.s.body.current = o.parent;
	}
	// Установить уровень просмотра
	setCurrent(id) {
		id = id ?? '';
		this.s.body.current = id;
	}
	// Очистить данные справочника
	clear() {
		this.s = {};
	}
	// Вернет найденные элементы
	find(val) {
		val = new RegExp(val, 'i')
		const a = this.s.body.list.filter((el) => {
			if (el.type === 'folder') return false;
			return el.list.findIndex((el) => val.test(el)) >  -1 
		});
		return a;
	}
	// Вернет текущий уровень для просмотра
	get list() {
		const cur = this.s?.body?.current;
		if (cur === undefined) return [];
		const a = this.s.body.list.filter((el) => el.parent === cur);
		return a;
	}
}

export default new Data();
