import { useEffect, useState } from 'react';
import Error from '../error';
import './style.css';
import Label from './lable';

const InputFile = ({ s, v, e, setValue }) => {
	const {
		_id,
		size = 5, //по умолчанию max size = 5мб
		name,
		title = '',
		disabled,
		width = '204px',
		style = {},
	} = s;
	// Преобразование размера в байты
	const max = +size * 1048576;

	// Значение
	const [val, setVal] = useState(v ? v : '<Не выбран>');

	// Массив ошибок - получаем пропсами из mobx
	const [err, setErr] = useState();
	// Индикатор Показывали ошибки или нет
	const [status, setStatus] = useState(false);

	useEffect(() => {
		setErr(e);
	}, [e]);

	let cls = ['skin-input-file', 'input-file'];
	if (err) cls.push('error');
	cls = cls.join(' ');

	const stlE = {
		backgroundImage: 'url("skin/img/com_edit.svg")',
	};
	const stlD = {
		backgroundImage: 'url("skin/img/com_delete.svg")',
	};
	const stlL = {
		backgroundImage: 'url("skin/img/com_view.svg")',
	};

	style.width = width;

	return (
		<div className={cls} style={style} >
			<Label label={s?.label} />
			<div className="file" onClick={onFocus} >
				<span className="name">{val}</span>
				<div className="ctrl" >
					{!disabled ? (
						<label
							style={stlE}
							className="edit ico"
							onChange={onChange}
							title="Редактировать"
						>
							<input
								id={_id}
								type="file"
								title={title}
								name={name}
							/>
						</label>
					) : null}
					<span
						className="load ico"
						title="Загрузить"
						style={stlL}
						onClick={dowload}
					></span>
					<span
						className="del ico"
						title="Удалить"
						style={stlD}
						onClick={del}
					></span>
				</div>
			</div>

			{/* Ошибки */}
			{status ? (
				<Error err={err} onClick={() => setStatus(false)} />
			) : null}
		</div>
	);

	function onChange(e) {
		e.preventDefault();
		setStatus(false);
		setErr(null);

		const { files } = e.target;
		if (files && files.length > 0) {
			if (files[0].size > max) {
				setErr(`Размер файла превышает ${size}мб`);
				setStatus(true);

				setValue(name, null);
				return;
			}
			setVal(files[0].name);

			let fd = new FormData();
			fd.append(name, files[0]);
			setValue(name, fd);
		}
	}

	// Удаление
	function del() {
		setValue(name, '');
		setVal('<Не выбран>');

		setStatus(false);
		setErr(null);
	}

	// Показываем список ошибок
	function onFocus(e) {
		if (err && !status) {
			setStatus(true);
		}
	}

	function dowload() {
		alert('Загрузка файла');
	}
};

export default InputFile;
