import { stackBak } from 'skin/event';

// Действия в Форме по type
function formAct(obj, form, data, story, d, action) {
	switch (obj.action) {
		// Получены даннные
		case 'loadData':
			break;

		// Получаем данные
		case 'reload':
			data.getData(d, action, form, story, obj?.table);
			break;

		// Сформированы Пустые данные под структуру
		// Начинаем получать данные
		case 'defData':
			data.getData(d, action, form, story);
			break;

		// Получена структуры формы
		case 'loadDef':
			data.defData(action, form);
			break;

		// Закрытие формы
		case 'exit':
			exit();
			break;

		// Валидация формы
		case 'check':
			form.check(story, data, action);
			break;

		// Сохранение формы
		case 'save':
			form.save(d, story, data, action);
			break;

		// Удаление формы
		case 'delete':
			form.del(d, story, action);
			break;

		// Добавление с  формы
		case 'add':
			form.add(story, data);
			break;
		default:
			console.log(obj);
			alert('[Форма] Действие не определено');
	}

	// Выйти из формы
	function exit() {
		form.close();
		story.kill();
		stackBak();
	}
}

export default formAct;
