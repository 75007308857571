import LogoL from 'skin/cmp/logo-l';
import Command from './command';
import './style.css';
import cnfTask from 'config_task';

const Footer = () => {
	let cls = ['skin-footer', 'clr-t3', 'footer'];
	cls = cls.join(' ');
	return (
		<footer className={cls}>
			<LogoL />
			<div className="d-flex jc-between f-grow-1">
				<Command />
				<p>{cnfTask.copyright}</p>
			</div>
		</footer>
	);
};

export default Footer;
