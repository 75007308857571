import { useState, useEffect } from 'react';
import Title from './title';
import Flds from './flds';
import Btn from './btn';
import Mess from './mess';
import Info from './info';
import './style.css';
import Login from 'skin/auth/login';

const arr2 = [
	{
		tit: 'IP-адрес',
		val: '192.168.1.1',
	},
	{
		tit: 'Браузер',
		val: 'Информация о браузере',
	},
	{
		tit: 'Дата и время',
		val: new Date().toLocaleString(),
	},
];

const Win = (props) => {
	let cls = ['skin-win', 'window'];
	cls = cls.join(' ');
	const [mess, setMess] = useState([]);
	const [info, setInfo] = useState([]);
	useEffect(loadInfo, []);

	return (
		<div className={cls}>
			<Title title="Авторизация пользователя" />
			<form onSubmit={login}>
				<Flds />
				<Btn title="Войти" />
			</form>
			<Mess mess={mess} />
			<Info info={info} />
		</div>
	);
	// Запросить ситемную информацию
	function loadInfo() {
		document.getElementsByName('login')[0].focus();
		setInfo(arr2);
	}

	// Авторизация
	function login(e) {
		e.preventDefault();

		const login = document.getElementById('login').value;
		const password = document.getElementById('password').value;

		Login(login, password, setMess);
	}
};

export default Win;
