import api from 'skin/http';
import { space } from 'skin/event';
import cnfTask from 'config_task';
import { makeAutoObservable, runInAction } from 'mobx';
import { stackClear } from 'skin/event';

class Data {
	s = {};

	constructor(props) {
		makeAutoObservable(this);
	}

	// Получение данных пользователя
	get() {
		api.get('api/data/employee')
			.then((res) => {
				runInAction(() => {
					this.s = res.data.result;
				});
			})
			.catch((e) => {
				console.log('User get error', e);
			});
	}

	form() {
		const o = {
			type: 'form',
			title: 'Личный кабинет',
			data: {
				code: cnfTask.userForm ?? 'employee',
				info: [this.id],
			},
		};
		stackClear();
		space(o, true);
	}

	get id() {
		return this.s._id ?? '';
	}

	

	// Форрмирование Имени пользователя
	get name() {
		const s = this.s;

		const name = s.name?.first;
		const surname = s.name?.last;
		let r = name ? name.charAt(0) + '. ' : '';
		r = r && surname ? r + surname : '';
		if (r === '') r = s.login;
		return r;
	}

	// Формирование url аватара сотрудника
	get avatar() {
		const s = this.s;
		return this.s.avt
			? 'url(' + cnfTask.url + 'company/img/' + s.avt + ')'
			: 'url(skin/img/avatar.svg)';
	}
}

export default new Data();
