// Навесить обработчики событий
const addEvent = (self) => {
	//window.addEventListener('load', self.device)
	//window.addEventListener('resize', self.device)
	//window.addEventListener('scroll', self.naviFix)
	window.addEventListener('gr-event', self.event);
};
// Удалить обработчики событий
const removeEvent = (self) => {
	//window.removeEventListener('load', self.device)
	//window.removeEventListener('resize', self.device)
	//window.removeEventListener('scroll', self.naviFix)
	window.removeEventListener('gr-event', self.event);
};
// Создать пользовательское событие
function crtEvent(o) {
	o = o ?? { action: 'test' };
	const e = new CustomEvent('gr-event', {
		detail: o, // Должен быть как минимум один элемент
	});
	window.dispatchEvent(e);
}
// Обработка событий
function event(self, o) {
	o = o ?? '';
	const type = typeof o;
	if (type === 'string') {
		// Простое событие
	}
	if (type === 'object') {
		o.action = o.action ?? '';
		if (o.action === 'wait') return runWait(self, o);
		if (o.action === 'shadow') return runShadow(self, o);
		if (o.action === 'space') return runSpace(self, o);
		if (o.action === 'stack') return runStack(self, o);
		if (o.action === 'type') return runType(self, o.type);
		if (o.action === 'dictionary') return runDictionary(self, o);
		if (o.action === 'message') return runMessage(self, o.data);
		if (o.action === 'info') return runInfo(self, o.data);
	}

	console.log('Событие event не обработано!');
}

// Создать событие "Техническая информация"
function info(arr) {
	if (!arr) {
		crtEvent({ action: 'info', data: false });
		return;
	}
	crtEvent({ action: 'info', data: arr });
}
// Создать событие "Сообщение"
function message(text, title, action, type) {
	if (!text) {
		crtEvent({ action: 'message', data: false });
		return;
	}
	const data = { text, title, action, type };
	crtEvent({ action: 'message', data });
}
// Создать событие "Справочник Вкл/Выкл"
function dictionary(o) {
	const code = o?.code ?? false;
	const func = o?.func;

	crtEvent({ action: 'dictionary', code, func });
}
// Создать событие "Ожидание Вкл/Выкл"
function wait(type) {
	type = type ?? false;
	crtEvent({ action: 'wait', on: type });
}
// Создать событие "Тень Вкл/Выкл"
function shadow(type) {
	type = type ?? false;
	crtEvent({ action: 'shadow', on: type });
}
// Создать событие "Вернуться по стеку"
function stackBak(back) {
	back = back ?? 1;
	crtEvent({
		action: 'stack',
		type: 'back',
		back,
	});
}
// Создать событие "Очистить стек"
function stackClear() {
	crtEvent({
		action: 'stack',
		type: 'clear',
	});
}
// Создать событие "Смена типа отображения"
function type(val) {
	val = val ?? 'login';
	crtEvent({
		action: 'type',
		type: val,
	});
}
// Создать событие "Смена пространства"
function space(o, add, clear) {
	// add   - Добавить в стек
	// clear - true очистить стек, 'back' удалить последний элемент стека
	add = add ?? false;
	clear = clear ?? false;
	o.type = o.type ?? 'menu';
	o.title = o.title ?? 'Нет названия';
	o.data = o.data ?? {};
	if (add) {
		crtEvent({
			action: 'stack',
			type: 'add',
			clear,
			data: {
				type: o.type,
				title: o.title,
				data: o.data,
			},
		});
	}
	crtEvent({ action: 'space', type: o.type, data: o.data });
}

// Реализовать действия по "Технической информации"
function runInfo(self, o) {
	self.setState({ info: o });
}

// Реализовать действия по "Сообщению"
function runMessage(self, o) {
	self.setState({ message: o });
}

// Реализовать действия по "Стеку"
function runStack(self, o) {
	const data = o.data;
	if (o.type === 'add') {
		self.setState((old) => {
			let a = old.stack;
			if (o.clear === 'back') a = a.slice(0, a.length - 1);
			if (o.clear === true) a = [];
			old.stack = [...a, data];
			return old;
		});
	}
	if (o.type === 'back') return runStackBak(self, o.back);
	if (o.type === 'clear') return runStackClear(self);
}

//  Реализовать действия "Очистить стек"
function runStackClear(self, back) {
	self.setState({ stack: [] });
	space({});
}

//  Реализовать действия "Справочник Вкл/Выкл"
function runDictionary(self, o) {
	if (!o.code) o = false;
	self.setState({ dictionary: o });
}

//  Реализовать действия "Смена типа отображения"
function runType(self, val) {
	self.setState({ type: val });
}

//  Реализовать действия "Вернуться по стеку"
function runStackBak(self, back) {
	back = back ?? 1;
	let n = self.state.stack.length;
	if (n === 0) {
		space({});
		return;
	}
	n = n - back;
	const a = self.state.stack.slice(0, n);
	self.setState({ stack: a });
	n = a.length;
	if (n === 0) {
		space({});
		return;
	}
	space(a[n - 1]);
}

// Реализовать действия по "Ожиданию Вкл/Выкл"
function runWait(self, o) {
	const on = o.on ?? false;
	const shadow = o.shadow ?? on;
	self.setState({ wait: on, shadow });
}

// Реализовать действия по "Тень Вкл/Выкл"
function runShadow(self, o) {
	const on = o.on ?? false;
	self.setState({ shadow: on });
}
// Реализовать действия по "Смене пространства"
function runSpace(self, o) {
	self.setState({ space: { type: o.type, data: o.data } });
}

export {
	addEvent,
	removeEvent,
	event,
	crtEvent,
	wait,
	space,
	shadow,
	stackBak,
	stackClear,
	type,
	dictionary,
	message,
	info,
};
